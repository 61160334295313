<div [id]="block.blockId" [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'flex flex-column flex-wrap relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div class="flex justify-content-between flex-wrap">
                <div *ngFor="let item of blockWithoutButtons.blocks" class="w-1/2 md:w-full md:flex-1 flex align-items-center justify-content-center">
                    <img [src]="item.asset_webp?.url ? item.asset_webp.url : item.asset.url" alt="Image" class="!rounded-none w-full">
                </div>
            </div>
        </div>
    </div>
</div>
