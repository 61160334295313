 <div [id]="block.blockId" [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">

    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <div class="p-5 surface-card rounded-3xl">
                <div class="text-brand-color font-bold flex align-items-center mb-3">
                    <app-icon [icon]="block.icon.value" class="mr-3 text-brand-color"></app-icon>
                    {{ block.title }}
                </div>

                <div class="grid">
                    <div class="col-9">
                        <div class="flex flex-wrap block-">
                            <div *ngFor="let category of block.blocks; let last = last"
                                 class="text-white shrink-0 flex-[33%] min-h-0 mb-2 grow-0">
                                {{ category.title }}
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <app-asset [block]="block" blockClass="ml-auto"></app-asset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
