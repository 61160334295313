 <div [id]="block.blockId" [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">

    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div class="grid">
                <div *ngFor="let person of block.blocks" class="col-12 lg:col-6 xl:col-4">
                    <div class="bg-white-alpha-60 shadow-2 p-4 h-full" style="border-radius: 10px">
                        <div [innerHTML]="person.content_limited | safeHTML"
                             class="mt-0 mb-3 line-height-3 text-900"></div>

                        <div class="flex mt-3 md:mt-0">
                            <div [style]="'background-image:url('+ (person.asset_webp ? person.asset_webp?.url : person.asset?.url) +')'"
                                 class="w-5rem h-5rem border-circle bg-cover bg-no-repeat"></div>

                            <div class="flex flex-column justify-content-center ml-3">
                                <span class="text-900 font-medium mb-1">{{ person.title }}</span>
                                <div class="text-600 text-sm">{{ person.subtitle }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
