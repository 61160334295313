import {Component, Inject, OnInit}                from '@angular/core';
import {ActivatedRoute, Router}                   from "@angular/router";
import {CommonModule, DOCUMENT, ViewportScroller} from "@angular/common";
import {HttpClient, HttpClientModule}             from "@angular/common/http";
import {ComponentsModule}                         from "../../../components/components.module";
import {Environment, ENVIRONMENT}                 from "../../../models/util";
import {environment}                              from "../../../../environments/environment";
import {Page}                                     from "../../../components/page";
import {Category}                                 from "../../../models/generic";
import {Meta, Title}                              from "@angular/platform-browser";
import {PageComponentModel}                       from "../../../models/page";
import {TranslateService}                         from "@ngx-translate/core";
import {GoogleTagManagerService}                  from "../../../services/gtm.service";


@Component({
    selector   : 'app-blog-overview',
    standalone : true,
    imports: [
        CommonModule,
        HttpClientModule,
        ComponentsModule,
    ],
    providers  : [
        {
            provide : ENVIRONMENT,
            useValue: environment
        },
    ],
    templateUrl: './blog-overview.component.html',
})
export class BlogOverviewComponent extends Page implements OnInit {
    endpoint: string       = 'blog-overview';
    currentCategory        = '';
    categories: Category[] = [];

    constructor(
        @Inject(DOCUMENT) override readonly document: Document,
        @Inject(ENVIRONMENT) override readonly environment: Environment,
        override readonly gtm: GoogleTagManagerService,
        override readonly meta: Meta,
        override readonly title: Title,
        override readonly router: Router,
        override readonly httpClient: HttpClient,
        override readonly viewportScroller: ViewportScroller,
        override readonly translateService: TranslateService,
        public readonly activatedRoute: ActivatedRoute,
    ) {
        super(document, environment, gtm, meta, title, router, httpClient, viewportScroller, translateService);
    }

    returnUrl(): string {
        const [locale, ...path] = this.router.url
            .split('/')
            .slice(1);

        return `?lang=${locale}`;
    }

    override pageView(page: PageComponentModel) {
        return {
            ...super.pageView(page),
            page_type: 'blog',
        };
    }

    override mapPage(page: PageComponentModel): PageComponentModel {
        return {
            ...page,
            seo: page.seo ? {
                ...page.seo,
                canonical: page.seo.canonical.map(c => ({
                    ...c,
                    uri: `/${c.locale}/blog`

                }))
            } : undefined,
        };
    }

    override ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            const cat = this.activatedRoute.snapshot.queryParamMap.get('cat')
            if (cat !== null) {
                this.endpoint        = `blog-overview/${cat}`;
                this.currentCategory = cat;

                super.ngOnInit();
            } else {
                this.endpoint        = `blog-overview`;
                this.currentCategory = '';
                super.ngOnInit();
            }
        });

        super.ngOnInit();

        this.httpClient.get<{
            data: Category[]
        }>(`${this.environment.apiUrl}blog-categories${this.returnUrl()}`).subscribe(c => {
            this.categories = c.data.filter(c => c.related_count !== 0);
        });
    }

    navigateTo(category?: Category) {
        let queryParams = {};

        if (category) {
            queryParams = {
                cat: category.slug
            }
        }

        this.router.navigate(['./'], {relativeTo: this.activatedRoute, queryParams});
    }
}
