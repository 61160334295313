<div [id]="block.blockId" [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">

            <div class="text-center max-w-[249px] mx-auto ![*>p]:m-0">
                <img src="/assets/development/images/ratecard.png" alt="Not found" class="w-full mb-2 mx-auto !rounded-none">
                <div *ngIf="block.content_limited"
                     [class]="'line-height-3 [&>a]:text-primary [&>a]:no-underline [&>a]:font-bold [&>p]:m-0 ' + theme.current.content"
                     [innerHTML]="block.content_limited | safeHTML"></div>
            </div>
        </div>
    </div>
</div>
