import {Component, Input, OnInit} from '@angular/core';
import {Block}                    from "../../block";
import {Ancestor}         from "../../../models/util";
import {TranslateService}         from "@ngx-translate/core";

@Component({
    selector   : 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent extends Block implements OnInit {
    @Input() ancestors: Ancestor[] = [];

    currentLang = 'nl';

    constructor(
        private readonly translateService: TranslateService,
    ) {
        super();
    }

    ngOnInit() {
        this.currentLang = this.translateService.currentLang;
    }
}
