<div [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset) + ' mb-0'">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>
        <div [class]="'mt-7 flex flex-column flex-wrap relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')">
            <app-wysiwyg [blockClass]="'[&>h1]:mb-0 [&>p]:mt-0'" [block]="block" [theme]="theme"></app-wysiwyg>
            <div class="md:block !mt-8">
                <div class="grid">
                    <ng-container *ngFor="let tile of block.blocks;">
                        <ng-container *ngIf="tile.type === 'heroTile'">
                        <div class="md:col-4">
                            <a [href]="tile.entry ? tile.entry : (tile.url ? tile.url : safeURL)" class="no-underline"
                               [ngClass]="{'cursor-auto': !(tile.entry ? tile.entry : (tile.url ? tile.url : false))}">
                                <div class="p-5 h-full bg-endeavour-green shadow-2 border-round-xl">
                                    <div class="flex align-items-center mb-2">
                                        <app-icon [icon]="tile.icon.value" class="mr-3 text-brand-color"></app-icon>
                                    </div>
                                    <app-wysiwyg [theme]="theme" [block]="tile" class="text-white line-height-3 text-2xl mb-2"></app-wysiwyg>
                                    <span class="text-sm">Meer info <i class="pi pi-arrow-right"></i></span>
                                </div>
                            </a>
                        </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

