 <div [id]="block.blockId" [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.fullTheme + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'md:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <app-text [block]="block" [theme]="theme"></app-text>

            <div class="grid">
                <div *ngFor="let usp of block.blocks; let first = first" class="col-12 md:col-6 lg:col-3" [ngClass]="{'md:col-6 lg:col-3': first, 'md:col-6 lg:col-2 flex-1': !first}">
                    <div *ngIf="usp.icon && usp.icon.value !== 'noIcon'"
                         class="flex justify-content-center align-items-center border-circle bg-white-alpha-40 w-14 h-14 z-1 shadow-2 mb-3">
                        <app-icon [icon]="usp.icon.value" class="text-primary-darker text-base"></app-icon>
                    </div>

                    <div [innerHTML]="usp.title | safeHTML"
                         class="font-bold line-height-3 text-2xl mb-2 [&>p]:mb-0 [&>p]:mt-0"></div>
                    <div *ngIf="usp.content_limited" [innerHTML]="usp.content_limited | safeHTML"
                         class="line-height-3 [&>p]:mb-0 [&>p]:mt-0 mb-3"></div>

                    <app-button *ngFor="let button of usp.blocks" [block]="button" [theme]="theme"
                                blockClass="mr-0 md:mr-3 !mb-2"></app-button>
                </div>
            </div>
        </div>
    </div>
</div>
