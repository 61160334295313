import {Component, Inject, OnInit}                from '@angular/core';
import {CommonModule, DOCUMENT, ViewportScroller} from "@angular/common";
import {Router}                                   from "@angular/router";
import {HttpClient, HttpClientModule}             from "@angular/common/http";
import {ComponentsModule}                         from "../../components/components.module";
import {Environment, ENVIRONMENT}                 from "../../models/util";
import {environment}                              from "../../../environments/environment";
import {Page}                                     from "../../components/page";
import {PageComponentModel}                       from "../../models/page";
import {ButtonModule}                             from "primeng/button";
import {RippleModule}                             from "primeng/ripple";
import {TranslateModule, TranslateService}        from "@ngx-translate/core";
import {Meta, Title}                              from "@angular/platform-browser";
import {GoogleTagManagerService}                  from "../../services/gtm.service";

@Component({
    selector   : 'app-page',
    standalone : true,
    imports: [
        CommonModule,
        HttpClientModule,
        ComponentsModule,
        ButtonModule,
        RippleModule,
        TranslateModule,
    ],
    providers  : [
        {
            provide : ENVIRONMENT,
            useValue: environment
        },
    ],
    templateUrl: './page.component.html',
})
export class PageComponent extends Page implements OnInit {
    endpoint: string = '';

    constructor(
        @Inject(DOCUMENT) override readonly document: Document,
        @Inject(ENVIRONMENT) override readonly environment: Environment,
        override readonly gtm: GoogleTagManagerService,
        override readonly meta: Meta,
        override readonly title: Title,
        override readonly router: Router,
        override readonly httpClient: HttpClient,
        override readonly viewportScroller: ViewportScroller,
        override readonly translateService: TranslateService,
    ) {
        super(document, environment, gtm, meta, title, router, httpClient, viewportScroller, translateService);
    }

    override ngOnInit() {
        super.ngOnInit();
    }

    override pageView(page: PageComponentModel) {
        const url = this.returnUrl();

        return {
            ...super.pageView(page),
            page_type: url.includes('home-page') ? 'home' : 'page',
        };
    }

    returnUrl(): string {
        const [url, ...path] = this.router.url
            .split('/')
            .slice(1);

        const [locale, ...queryParams] = url.split('?');

        return (path.length === 0) ? `home-page?lang=${locale}` : `pages${this.router.url}?lang=${locale}`;
    }

    override mapPage(page: PageComponentModel, url: string): PageComponentModel {
        if (url.includes('home-page')) {
            return {
                ...page,
                seo: page.seo ? {
                    ...page.seo,
                    canonical: page.seo.canonical.map(c => ({
                        ...c,
                        uri: `/${c.locale}`

                    }))
                } : undefined,

            };
        }

        return super.mapPage(page, url);
    }
}
