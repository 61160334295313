<div [id]="block.blockId" [class]="'z-1 relative ' + (blockClass ? (blockClass + ' ') : '') + theme.current.card + (block.class_overwrite ? (' ' + block.class_overwrite) : '' ) + ' ' + (componentOffset)">
    <div class="container m-auto relative overflow-hidden px-3 lg:px-0">
        <app-seaweed *ngIf="block.background_seaweed" [alignment]="block.seaweed_alignment.value"
                     [theme]="theme"></app-seaweed>

        <div [class]="'relative ' + (container ? 'lg:px-6 lg:px-8 xl:!px-42' : '')"
             [ngClass]="{'py-7': theme.theme !== 'light'}">
            <div [class]="'relative overflow-hidden ' + (block.asset ? 'grid' : '')">
                <div *ngIf="block.asset" class="col-12 lg:col-4 text-center lg:text-left">
                    <img [src]="(block.asset_webp.url ? block.asset_webp.url : block.asset.url)" alt="Image" class="block max-w-full mx-auto lg:mx-0">
                </div>

                <div [class]="(block.asset ? 'col-12 p-0 lg:p-3 lg:col-8 text-center lg:text-left' : 'grid')">
                    <div *ngIf="block.asset; else col3;" class="grid col-12 m-0 lg:col-10 p-0 lg:ml-3">
                        <div *ngFor="let item of block.blocks" class="col-6">
                            <span class="text-primary font-bold text-5xl">{{ item.text }}</span>
                            <div [innerHTML]="item.content_limited | safeHTML"
                                 class="[&>p]:mb-0 [&>p]:mt-3 text-700"></div>
                        </div>
                    </div>

                    <ng-template #col3>
                        <div *ngFor="let item of block.blocks" class="col-6 md:col-3 text-center lg:text-left">
                            <span class="text-primary font-bold text-5xl">{{ item.text }}</span>
                            <div [innerHTML]="item.content_limited | safeHTML"
                                 class="[&>p]:mb-0 [&>p]:mt-3 text-700"></div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
