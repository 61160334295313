import {Component}               from '@angular/core';
import {Block}                   from "../../block";
import {GoogleTagManagerService} from "../../../services/gtm.service";
import {ViewportScroller}        from "@angular/common";

@Component({
    selector   : 'app-button',
    templateUrl: './button.component.html',
    styles     : `
      :host {
        display: contents;
      }
    `
})
export class ButtonComponent extends Block {
    constructor(
        private readonly gtm: GoogleTagManagerService,
        private readonly viewportScroller: ViewportScroller
    ) {
        super();
    }

    beforeOrAfterClick(e: Event) {
        if (this.block.url) {
            const constructedURL = new URL(this.block.url)
            const urlWithoutHash = constructedURL.href.replace(constructedURL.hash, '');

            if (urlWithoutHash.replace(window.location.href, '').length < 2) {
                e.preventDefault();
                const [_, id] = constructedURL.hash.split('#');

                this.viewportScroller.scrollToAnchor(id);
            }
        }

        this.trackClick();
    }

    private trackClick() {
        if (this.block.datalayer && this.block.datalayer.length > 0) {
            const trackingData = this.block.datalayer.reduce((r, i) => {
                r[i.col1] = i.col2;
                return r;
            }, <{ [value: string]: string }>{})

            this.gtm.trackEvent(trackingData);
        }
    }
}
